article {
    text-align: justify;
}

.list-site {
    margin: auto;
    list-style: disc;
    padding: 0 0 0 24px;
}

.list-site li {
    margin: 6px;
}

.content-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image-and-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    padding: 0 8px;
    gap: 20px;
    width: 100%;
}

.image-and-text img {
    border-radius: 4px;
    box-shadow: rgba(194, 18, 146, 0.2) 0px 2px 8px 0px;
    opacity: 0.95;
}


@media only screen and (min-width: 768px) {
    .image-and-text {
        flex-direction: row;
        max-width: 1024px;
    }
}

@media only screen and (min-width: 990px) {
    .list-site li {
        margin: 24px 0;
    }

    .image-and-text {
        max-width: 1024px;
    }
}