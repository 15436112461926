@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;500;600&display=swap');

body,
html {
  font-size: 16px;
  line-height: 1.42857143;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Figtree', sans-serif;
  color: #444;
}