.banner-section {
    margin: 24px 0 40px 0;
}

.internal-banner {
    position: relative;
    text-align: center;
    margin-top: 15px;
    width: 100%;
    overflow: hidden;
}

.description-banner.-internal {
    text-align: left;
}

.description-banner {
    padding: 20px;
}

.internal-banner .image {
    margin: 0 -50%;
}

img {
    vertical-align: middle;
    border: 0;
}

.description-banner.-internal>.description {
    bottom: 0;
    left: 0;
}

.description-banner.-internal>.description>.title {
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0;
    text-transform: uppercase;
    font-size: 26px;
    line-height: normal;
}

.description-banner .description>.subtitle,
.description-banner .description>.title {
    margin: 0;
    font-weight: 700;
    color: #fff;
    display: inline-block;
}



@media (max-width: 479px) {
    .description-banner.-internal {
        background-color: #2D033B;
    }
}

@media (max-width: 767px) {
    .internal-banner .image {
        height: 200px;
    }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
    section {
        overflow-y: overlay;
        overflow-x: hidden;
    }
}

@media (min-width: 480px) {
    .description-banner.-internal>.description {
        padding: 0 15px;
    }
}

@media (min-width: 480px) {
    .description-banner {
        position: absolute;
        height: 100%;
        top: 0;
        margin: auto;
        left: 0;
        right: 0;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 480px) {
    .description-banner.-internal>.description>.title {
        padding-left: 35px;
        padding-right: 35px;
    }
}

@media (min-width: 480px) {

    .-alpha>.description>.subtitle,
    .-alpha>.description>.title {
        background-color: rgb(45, 3, 59, .7);
    }
}