.header {
    padding: 0px 10px;
}

.menu-bar {
    height: 80px;
    width: calc(100% - 40px);
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 4;
    left: 0;
    right: 0;
    margin: 10px auto;
    padding: 0px 15px;
    box-shadow: 0px 5px 30px 0px rgba(127, 103, 157, 0.28);
}

a {
    color: #2D033B;
}

a:focus,
a:hover {
    color: #C21292;
}

.desktop-only {
    display: none !important;
}

.mobile-only {
    display: block !important;
}

.menu-toggle {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border: 1px solid transparent;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    position: relative;
    align-self: center;
    margin-top: 4px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.menu-toggle>.icon-bar {
    display: block;
    margin-bottom: 4px;
    width: 30px;
    height: 3px;
    border-radius: 1.5px;
    background: #fff;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.menu-bar .menu-toggle>.icon-bar {
    background: #C21292;
}



@media screen and (min-width: 1024px) {
    .menu-bar {
        width: 100%;
        max-width: 95%;
        margin: 20px auto;
        padding: 0px 25px 0px 25px;
        height: 100px;
        border-radius: 12px;
    }

    .desktop-only {
        display: flex !important;
    }

    .mobile-only {
        display: none !important;
    }

    .menu-bar .menu-bar-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        height: 100%;
    }

    .menu-bar .menu-bar-items .menu-bar-item {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0px 15px;
        height: 100%;
    }

    .menu-bar .menu-bar-items .menu-bar-item a:not(.btn) {
        font-weight: 700;
        text-transform: uppercase;
        font-size: calc(100vw * 0.011);
        transition: color .2s ease;
    }
}

@media screen and (min-width: 1200px) {
    .menu-bar {
        padding: 0px 40px 0px 45px;
    }
}

@media (min-width: 1150px) {
    .internal-banner .description-banner.-internal>.description {
        margin-top: 150px;
    }
}

@media screen and (min-width: 1275px) {
    .menu-bar {
        max-width: calc(1275px - 57.5px);
    }
}

@media screen and (min-width: 1450px) {
    .menu-bar .menu-bar-items .menu-bar-item a:not(.btn) {
        font-size: 16px;
    }
}