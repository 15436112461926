@media only screen and (max-width: 480px) {
    .carousel-inner>div>img {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    article {
        text-align: justify;
    }
}