.footer {
    padding: 0px 0px 0px;
    width: 100%;
    margin: auto;
    max-width: 1300px;
    z-index: 4;
}

.footer h2 {
    color: #C21292;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 20px;
}

.footer .inner {
    background: #F3F8FF;
    border-radius: 10px 10px 0 0;
    padding: 30px 0;
    overflow: hidden;
}

.footer .inner .container {
    position: relative;
    max-width: 100%;
    z-index: 1;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
}

.footer .inner .container:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background: url('https://www.hcor.com.br/wp-content/themes/hcor-hc/css/images/waves3.png') no-repeat;
    z-index: -1;
}

.footer .menu {
    margin-top: 0px;
    padding: 0px;
    padding-left: 8px;
}

.footer .menus {
    margin: 24px 0 20px;
}

.footer .nav {
    display: flex;
    justify-content: space-between;
}

.footer .nav .menu {
    color: #2D033B;
    font-size: 14px;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 440px;
    max-width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
}

li {
    margin-bottom: 20px;
}

.footer a {
    color: inherit;
    text-decoration: none;
    outline: 0;
    transition: color 0.2s;
    cursor: pointer;
}

.footer .contacts {
    background: url('https://www.hcor.com.br/wp-content/themes/hcor-hc/css/images/waves3.png');
}

.footer .contacts>div~div {
    margin-top: 40px;
}

.footer .social {
    display: flex;
    gap: 12px;
    margin-bottom: 50px;
}

.footer .copyright {
    background: #2D033B;
    border-radius: 0;
    color: #FFF;
    font-size: 13px;
    width: 100%;
    margin-top: -1px;
}

.footer .copyright .container {
    height: 120px;
    max-width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer .copyright .container * {
    text-align: center;
}

.footer-logo {
    width: 180px;
    height: 78px;
}

.copyright-menu {
    margin: 10px;
}

@media only screen and (min-width: 576px) {
    .footer .contacts {
        display: flex;
        justify-content: space-between;

    }

    .footer .contacts>div {
        width: 50%;
    }

    .footer .contacts>div~div {
        margin-top: 0;
    }
}

@media only screen and (min-width: 768px) {
    .footer .inner {
        padding: 30px 15px 15px;
    }

    .footer .inner .container {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer .links {
        width: 70%;
    }

    .footer .menus {
        margin-top: 22px;
    }

    .footer .contacts>div {
        width: 100%;
    }

    .footer-logo {
        width: 160px;
        height: 72px;
    }
}

@media only screen and (min-width: 992px) {
    .footer .links {
        display: flex;
        justify-content: space-between;
        width: 70%;
    }

    .footer .menus {
        width: 50%;
        margin-top: 22px;
    }

    .footer .nav .menu {
        font-size: 16px;
    }

    .footer .contacts {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 22px;
    }

    .footer .contacts>div~div {
        margin-top: 40px;
    }

    .footer .copyright .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        max-width: calc(100% - 60px);
        height: 120px;
    }
}

@media only screen and (min-width: 1300px) {
    .footer {
        padding: 0px 0px 10px;
    }

    .footer .copyright {
        border-radius: 0 0 8px 8px;
    }

    .footer-logo {
        width: 220px;
        height: 90px;
    }
}