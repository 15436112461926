.media-container {
    padding: 0;
    margin-bottom: 14px;
    width: 100%;
}

.flex-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* Just using width here causes the images to fail to display on mobile */
    min-width: 100%;
    max-width: 100%;
}

.image-item {
    margin: 4px;
    cursor: pointer;
    object-fit: cover;
}

.image-wrapper {
    position: relative;
}

.modal-content,
.modal-header {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    border: none
}

.modal-content .modal-header .btn-close {
    background-color: rgba(255, 255, 255, 0.25);
}

.modal-content .modal-body {
    padding: 0;
}

.modal-content .modal-body img {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 1441px) {
    .flex-wrapper {
        justify-content: center;
    }
}