.contact-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

hr {
    width: 60%;
}

.contact-unit {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 8px;
    padding: 8px;
}

.unit-1 {
    display: flex;
    position: relative;
    justify-content: center;
    margin: auto;
    height: 400px;
}

.workes-char {
    position: absolute;
    left: -10%;
    width: 180px;
    height: 400px;
}

.section-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-section-2 {
    margin: 32px 0;
    padding: 24px;
    background-color: rgba(184, 184, 184, 0.25);
}

.about-section-2 .section-flex {
    flex-direction: column;
}

.about-section-3 {
    margin: 32px 0;
    padding: 24px;
}

.section-3-content {
    text-align: center;
    width: 50%;
}

.section-3-img {
    width: 320px;
    height: 280px;
}

.second {
    padding: 40px;
}

@media only screen and (min-width: 768px) {
    .workes-char {
        left: 20px;
    }

    .section-3-img {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 996px) {
    .section-3-content {
        width: 100%;
    }

    .about-section-3 .section-flex {
        flex-direction: column-reverse;
    }
}