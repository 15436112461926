.btn-wpp {
    color: #fff;
    font-weight: bold;
    padding: 8px 12px;
    background-color: green;
    border-radius: 6px;
    width: fit-content;
    cursor: pointer;
}

.btn-wpp:visited {
    color: #fff;
    background-color: green;
}

.btn-wpp:hover {
    color: #fff;
    background-color: rgba(0, 72, 0, 0.9);
}