.nav-top {
    background-color: #fefefe;
    max-width: 1275px;
    margin: auto;
}

.nav-top>ul li.phone a {
    color: #BB9CC0;
    font-size: 16px;
    font-weight: 600;
}

.nav-top>ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 4px;
}

.nav-top>ul>li {
    margin: 0;
}

.nav-top>ul .nav-top-item-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
}

.nav-top>ul .nav-top-item-group li {
    margin: 0px 0.4rem;
}

@media screen and (min-width: 1024px) {
    .nav-top>ul {
        padding: 20px 10px !important;
    }

    .nav-top>ul .nav-top-item-group li {
        margin: 0px 1rem;
    }
}