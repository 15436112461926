.dropdown-wrapper {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-evenly;

    top: 142px;
    left: 0;
    right: 0;
    height: 200px;

    background: rgba(255, 255, 255, 0.8);
    opacity: 0.94;
    z-index: -1;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.menu {
    width: 100%;
    margin-top: 16px;
    padding: 20px;
}

.menu-bar-item {
    font-size: 18px;
    font-weight: bold;
}