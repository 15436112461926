* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

a.active {
    font-weight: bold;
    color: #C21292;
    text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2),
        0px -2px 35px rgba(255, 255, 255, 0.3);
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

ol,
ul {
    box-sizing: border-box;
}

.article-text-content {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.internal-content {
    margin-top: 20px;
}

.internal-content h1 {
    color: #C21292;
    font-size: 1.5rem;
    margin: 0 0 40px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1305px;
    }

    .article-text-content {
        padding-right: 45px;
        padding-left: 45px;
    }
}